import React from 'react'

const SvgIcon9 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon9_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon9_svg__a)'>
      <path
        d='M30.17 17.281c-4.176 0-7.575 3.344-7.575 7.455 0 4.11 3.399 7.455 7.576 7.455s7.576-3.344 7.576-7.455c0-4.11-3.4-7.455-7.576-7.455zm0 11.8c-2.438 0-4.42-1.95-4.42-4.35 0-2.4 1.983-4.35 4.422-4.35 2.438 0 4.419 1.95 4.419 4.35 0 2.4-1.982 4.35-4.42 4.35zm-6.186 16.597c-.87 0-1.58.695-1.58 1.556v6.21c0 .856.705 1.556 1.58 1.556.87 0 1.58-.695 1.58-1.555v-6.21c0-.862-.71-1.557-1.58-1.557zm12.628 0c-.87 0-1.58.695-1.58 1.556v6.21c0 .856.705 1.556 1.58 1.556.875 0 1.58-.695 1.58-1.555v-6.21a1.573 1.573 0 00-1.58-1.557z'
        fill='#2C405B'
      />
      <path
        d='M44.219 29.64c.868-14.904-12.495-23.99-13.063-24.37a1.591 1.591 0 00-1.803.021c-.541.382-13.251 9.446-13.037 24.282.034 2.325.366 4.574.833 6.667-.017.017-.039.028-.056.044C13.225 39.798 11 44.774 11 49.944c0 .852.703 1.547 1.577 1.547.873 0 1.576-.69 1.576-1.546 0-3.762 1.414-7.408 3.92-10.22.309 1.004.64 2 .99 2.99.22.617.818 1.038 1.488 1.038h8.17v5.314c0 .85.705 1.546 1.578 1.546.873 0 1.576-.69 1.576-1.546-1.767-2.386-1.767-4.158 0-5.314 1.769-1.158 4.475-1.158 8.121 0 .67 0 1.267-.414 1.487-1.039.28-.795.535-1.591.776-2.386 2.135 2.701 3.306 5.988 3.306 9.468 0 .85.704 1.546 1.577 1.546s1.577-.69 1.577-1.546c-.012-4.956-1.96-9.59-5.508-13.097.535-2.386.873-4.75 1.009-7.06h-.001zm-5.36 11.018h-6.988v-1.634c0-.851-.705-1.547-1.577-1.547-.873 0-1.577.69-1.577 1.547v1.634h-7.044c-1.065-3.126-2.152-7.042-2.208-11.13-.164-11.03 8.058-18.747 10.822-21.027 2.856 2.254 11.421 9.953 10.785 20.962-.203 3.623-.952 7.385-2.214 11.196l.001-.001z'
        fill='#2C405B'
      />
    </g>
  </svg>
)

export default SvgIcon9
