import React from 'react'

const SvgChallenge2 = props => (
  <svg fill='none' width={31.127} height={30} viewBox='0 0 31.127 30' {...props}>
    <path
      d='M15.603 0A7.93 7.93 0 008.02 7.226H23.29A8.028 8.028 0 0015.603 0zM11.53 4.794a4.553 4.553 0 018.083 0H11.53zm18.305 10.958h-2.705a14.09 14.09 0 00-.603-2.974l2.47-1.412a1.277 1.277 0 00-.553-2.431h-.013a1.27 1.27 0 00-.711.236l-.03-.04-2.179 1.242a13.053 13.053 0 00-1.206-1.83H6.724a13.049 13.049 0 00-1.206 1.83L3.342 9.131l-.013.021a1.263 1.263 0 00-.615-.176 1.286 1.286 0 00-1.299 1.273v.013c.01.445.255.853.644 1.071v.015l2.47 1.412c-.318.97-.527 1.973-.623 2.989h-2.7v.011a1.27 1.27 0 000 2.537v.021h2.7a13.75 13.75 0 00.6 2.982L2.22 22.61c-.017 0-.035.022-.051.03l-.123.068a1.263 1.263 0 00-.644 1.071c0 .71.576 1.286 1.286 1.286H2.7a1.31 1.31 0 00.616-.164l.013.028 2.17-1.24a11.096 11.096 0 0020.023 0l2.17 1.24v-.016c.194.122.415.19.643.195a1.286 1.286 0 001.3-1.273v-.012a1.26 1.26 0 00-.672-1.1l.013-.022-2.458-1.404c.306-.97.508-1.969.603-2.98h2.72a1.286 1.286 0 000-2.573l-.005.008zm-13.02 11.573v-11.88h-.014a1.298 1.298 0 10-2.594.014v11.85c-4.729-1.093-8-5.41-7.773-10.26a11.37 11.37 0 011.639-5.929H22.94a11.37 11.37 0 011.639 5.93 10.054 10.054 0 01-7.773 10.265l.01.01z'
      fill='#333'
    />
  </svg>
)

export default SvgChallenge2
