import React from 'react'

const SvgIcon8 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon8_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon8_svg__a)'>
      <path
        d='M31.035 12.998h21.464A2.503 2.503 0 0155 15.5v34.997a2.501 2.501 0 01-2.501 2.5H7.5A2.501 2.501 0 015 50.498V10.5A2.5 2.5 0 017.501 8h18.535l4.999 4.998zm-21.037 0V48h40V18.001H28.96l-4.99-5.003H9.997zM20.004 23h20.002v12.262a6.666 6.666 0 01-2.97 5.546L30 45.491l-7.03-4.688a6.667 6.667 0 01-2.97-5.55V23h.003zm5.002 12.262c0 .555.278 1.076.743 1.388L30 39.479l4.256-2.838c.464-.309.743-.83.742-1.387v-7.256h-9.996v7.263h.003z'
        fill='#2C405B'
      />
    </g>
  </svg>
)

export default SvgIcon8
