import React from 'react'

const SvgIcon3 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon3_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon3_svg__a)'>
      <path
        d='M38 50.2l-.2-.2c-.9-.9-2.3-1.2-3.5-.9-1.3.4-2.6.6-3.9.6-1.4 0-2.8-.2-4.1-.6-1.4-.4-2.8.2-3.6 1.4-.5.8-.6 1.8-.2 2.7.4.9 1.1 1.6 2 1.8 1.9.6 3.9.9 5.9.9 2.1 0 4.1-.3 6.1-.9.9-.3 1.6-1 2-1.8.3-.9.3-1.9-.2-2.7l-.3-.3zm3.1-25.7c1 1 1.9 2.1 2.6 3.4.7 1.2 1.2 2.5 1.5 3.9.3 1.4 1.5 2.4 2.9 2.5h.1c.9 0 1.8-.4 2.4-1.1.6-.7.9-1.7.7-2.6-.4-2-1.1-3.9-2.1-5.6-1-1.8-2.2-3.4-3.7-4.9-.7-.6-1.6-1-2.5-.8-.9.1-1.8.6-2.3 1.4-.8 1.2-.6 2.8.4 3.8zm-28.6 9.6h.4c1.4-.2 2.6-1.3 2.7-2.7.2-1.4.5-2.8 1.1-4.1.6-1.3 1.3-2.5 2.2-3.6.9-1.1 1-2.6.1-3.8-.6-.8-1.4-1.3-2.4-1.3-.9 0-1.9.3-2.5 1.1-1.3 1.5-2.4 3.2-3.2 5.1-.8 1.9-1.3 3.8-1.6 5.9-.1.9.2 1.9.9 2.5.7.6 1.5.9 2.3.9zm11.2-12.2c.6 1 1.6 1.6 2.7 1.6H34c1.1 0 2.2-.6 2.7-1.6l3.8-6.6c.6-1 .6-2.2 0-3.1l-3.8-6.6C36.2 4.6 35.1 4 34 4h-7.6c-1.1 0-2.2.6-2.7 1.6l-3.8 6.6c-.6 1-.6 2.2 0 3.1l3.8 6.6zm1.4-8.1l2.5-4.4h5.1l2.5 4.4-2.5 4.4h-5.1l-2.5-4.4zm-7.4 23.7c-.6-1-1.6-1.6-2.7-1.6H7.3c-1.1 0-2.2.6-2.7 1.6L.8 44.1c-.6 1-.6 2.2 0 3.1l3.8 6.6c.6 1 1.6 1.6 2.7 1.6H15c1.1 0 2.2-.6 2.7-1.6l3.8-6.6c.6-1 .6-2.2 0-3.1l-3.8-6.6zm-1.4 8.1L13.8 50H8.6l-2.5-4.4 2.5-4.4h5.1l2.6 4.4z'
        fill='#2C405B'
      />
      <path
        d='M38.5 47.6c-.6-.9-.6-2.1 0-3.1l3.8-6.6c.5-1 1.6-1.6 2.7-1.6h7.7c1.1 0 2.1.6 2.7 1.6l3.8 6.6c.5.9.6 2.1-.1 3.1l-3.8 6.6c-.5 1-1.6 1.6-2.7 1.6H45c-1.1 0-2.1-.6-2.7-1.6l-3.8-6.6zm7.8 2.8L43.7 46l2.5-4.4h5.1L54 46l-2.5 4.4h-5.2z'
        fillRule='evenodd'
        fill='#FD5C1F'
      />
    </g>
  </svg>
)

export default SvgIcon3
