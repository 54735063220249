import React from 'react'

const SvgChallenge1 = props => (
  <svg fill='none' width={33.152} height={30} viewBox='0 0 33.152 30' {...props}>
    <path
      d='M1.823 30C.315 30-.417 28.34.245 27.07 1.15 25.331 14.28 2.455 15.09.991c.728-1.312 2.465-1.334 3.193 0 1.11 2.037 13.944 24.82 14.584 26.014.805 1.486-.215 2.994-1.586 2.994H1.823zm14.047-9.653h1.552l1.042-10.19h-3.629l1.035 10.19zm-1.035 1.46v2.912h3.63V21.81l-3.63-.003z'
      fill='#333'
    />
  </svg>
)

export default SvgChallenge1
