import React from 'react'

const SvgIcon2 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon2_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon2_svg__a)'>
      <path
        d='M43.822 31.867l9.375 3.75a1.875 1.875 0 011.178 1.74V45c0 4.316-7.8 11.25-11.25 11.25S31.875 49.316 31.875 45v-7.642c0-.767.466-1.455 1.177-1.74l9.376-3.75c.447-.18.947-.18 1.395 0zM41.25 5.625c.906 0 1.682.647 1.845 1.537l.03.338v3.75H52.5c1.035 0 1.875.84 1.875 1.875V22.5c0 1.035-.84 1.875-1.875 1.875V30h-3.75v-5.625h-9.375v3.75c0 .906-.647 1.682-1.538 1.845L37.5 30h-15a1.875 1.875 0 01-1.875-1.875v-3.75H11.25V48.75H30v3.75H9.375a1.875 1.875 0 01-1.845-1.538l-.03-.337v-26.25A1.875 1.875 0 015.625 22.5v-9.375c0-1.036.84-1.875 1.875-1.875h9.375V7.5c0-.906.647-1.682 1.538-1.845l.337-.03h22.5zm1.875 30l-7.5 3V45c0 2.044 5.494 7.02 7.275 7.466l.225.034c1.47 0 7.5-5.359 7.5-7.5v-6.375l-7.5-3zm-7.5-11.25h-11.25v1.875h11.25v-1.875zm15-9.375H9.375v5.625h41.25V15zm-11.25-5.625h-18.75v1.875h18.75V9.375z'
        fill='#2C405B'
      />
      <path
        d='M37.354 43.748l3.75 3.75.236.187a1.5 1.5 0 001.886-.188l5.625-5.624-2.122-2.123-4.564 4.568-2.689-2.693-2.122 2.123z'
        fill='#FD5C1F'
      />
    </g>
  </svg>
)

export default SvgIcon2
