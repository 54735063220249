import React from 'react'

const SvgIcon5 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon5_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon5_svg__a)'>
      <path
        d='M53.543 21.217c0-.003 0-.003 0 0l-.004-3.303c0-3.113-2.468-6.597-5.771-6.597H31.3l-.168-.322h-.008l-.686-1.294a3.945 3.945 0 00-3.51-2.126H9.169c-2.963 0-5.37 2.475-5.37 5.52v33.911c0 3.045 2.407 5.52 5.37 5.52h41.79c2.962 0 5.37-2.475 5.37-5.52V26.152c.003-1.8-1.189-3.705-2.786-4.935zm-3.795-2.88l.075 1.542H35.835l-2.557-4.823H47.29c1.148 0 2.524.893 2.457 3.281zm2.79 28.242c0 1.215-.923 2.205-2.055 2.205H9.7c-1.132 0-2.055-.99-2.055-2.205V13.522c0-1.215.923-2.205 2.055-2.205H26.93a.66.66 0 01.581.357l5.861 10.001c.288.543.852.882 1.467.881h14.744c1.19 0 2.956 1.77 2.956 2.959v21.064z'
        fill='#2C405B'
      />
      <path
        d='M42.86 29.628H23.722l2.23-2.23c.64-.64.64-1.695 0-2.336a1.659 1.659 0 00-2.336 0l-5.045 5.042c-.016.02-.023.043-.04.06-.285.3-.446.7-.448 1.116 0 .908.743 1.652 1.651 1.652H42.86c.908 0 1.651-.744 1.651-1.652 0-.909-.743-1.652-1.651-1.652zm1.651 8.26c0-.91-.743-1.653-1.651-1.653H19.734c-.908 0-1.651.744-1.651 1.652 0 .908.743 1.652 1.651 1.652h19.092l-2.23 2.226c-.641.641-.641 1.695 0 2.336.64.641 1.695.641 2.336 0l4.958-4.959c.387-.302.615-.764.621-1.255z'
        fill='#FD5C1F'
      />
    </g>
  </svg>
)

export default SvgIcon5
