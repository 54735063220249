import React from 'react'

const SvgIcon6 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon6_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon6_svg__a)'>
      <path
        d='M22.755 50.62H9.375A6.855 6.855 0 012.5 43.8V9.3a6.855 6.855 0 016.875-6.8H44.29a6.855 6.855 0 016.875 6.815V31.5a2 2 0 11-4 0V9.315A2.85 2.85 0 0044.29 6.5H9.375A2.85 2.85 0 006.5 9.315v34.5a2.85 2.85 0 002.875 2.82h13.38a2 2 0 110 4v-.015z'
        fill='#2C405B'
      />
      <path
        d='M16.965 35.38a2 2 0 01-2-2V23.5a2 2 0 114 0v9.88a2 2 0 01-2 2zM26.18 13.545q2 0 2 2V33.08q0 2-2 2t-2-2V15.545q0-2 2-2z'
        fill='#2C405B'
      />
      <path
        d='M35.39 35.38a2 2 0 01-2-2V23.5a2 2 0 014 0v9.88a2 2 0 01-2 2zm6.915 22.12c-5.345 0-10.29-3.175-14.7-9.43-.09-.126-.17-.26-.235-.4l-.05-.105c0-.035-.035-.075-.055-.115-.02-.04-.07-.195-.1-.3a2.7 2.7 0 01-.085-.36 2.26 2.26 0 010-.58v-.105a2.425 2.425 0 01.03-.465v-.055c.019-.105.046-.209.08-.31a1.92 1.92 0 01.1-.3c0-.04.035-.08.05-.115l.045-.095c.079-.158.17-.308.275-.45 4.34-6.195 9.29-9.36 14.635-9.36 5.345 0 10.28 3.17 14.705 9.43a.857.857 0 01.075.11c.045.07.085.145.125.22v.035c.139.245.226.515.255.795.034.144.051.292.05.44v.5c0 .147-.016.293-.05.435a1.96 1.96 0 01-.215.725l-.04.08a2.599 2.599 0 01-.155.285.715.715 0 01-.07.1c-4.405 6.225-9.34 9.39-14.67 9.39zm-11.1-11.28c3.53 4.84 7.26 7.28 11.1 7.28s7.56-2.445 11.075-7.27c-3.5-4.83-7.24-7.275-11.085-7.275-3.845 0-7.585 2.445-11.09 7.265z'
        fill='#FD5C1F'
      />
      <path
        d='M42.275 52.03a5.825 5.825 0 01-4.61-2.23 5.765 5.765 0 01-1.2-4.26 5.845 5.845 0 016.44-5.08 5.805 5.805 0 015.17 6.455 5.84 5.84 0 01-5.8 5.115zm0-7.605A1.83 1.83 0 0040.44 46c-.058.467.076.938.37 1.305.302.39.75.642 1.24.695a1.84 1.84 0 002.055-1.575v-.21a.845.845 0 010-.115 1.605 1.605 0 00-1.5-1.665h-.11l-.22-.01z'
        fill='#FD5C1F'
      />
    </g>
  </svg>
)

export default SvgIcon6
