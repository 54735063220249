import React from 'react'

const SvgIcon7 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon7_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon7_svg__a)'>
      <path
        d='M47.38 55H12.839c-2.522.016-3.853-2.084-3.838-4.605v-34.51c.016-2.522 2.084-4.59 4.605-4.606H16.675q.315-.012.66 0c.694.025.87.479.877.838.016.783 0 .391 0 1.22 0 .096-.038.96-1.011.984q-.62.016-3.507.058c-.6.008-1.555 1.129-1.563 1.563v34.39c1.032 1.584.219 1.571 1.563 1.563h32.827c1.344.008 1.571-1.013 1.563-1.563v-34.39c.043-.514-.866-1.611-2.21-1.62q-2.234.037-2.85 0c-.808-.05-1.014-.367-1.016-.937-.003-.802-.03-.39 0-1.187.017-.45-.119-.894 1.024-.919q.282-.006.511 0h3.07c2.522.016 4.59 2.084 4.605 4.605v34.51c.016 2.522-1.317 4.622-3.837 4.606zm-5.153-26.666L29.686 40.83c-.754.75-1.973.75-2.727 0l-7.634-7.607a1.915 1.915 0 010-2.717 1.932 1.932 0 012.726 0l6.272 6.248L39.5 25.617a1.932 1.932 0 012.726 0c.754.75.754 1.966 0 2.717zm-3.522-13.955H21.51a1.564 1.564 0 010-3.126h1.641C23.544 7.74 26.489 5 30.106 5c3.617 0 6.562 2.74 6.956 6.253h1.642a1.564 1.564 0 01.001 3.126zm-8.597-6.253a2.346 2.346 0 000 4.69 2.346 2.346 0 000-4.69z'
        fill='#2C405B'
      />
    </g>
  </svg>
)

export default SvgIcon7
