import React from 'react'

const SvgChallenge4 = props => (
  <svg fill='none' width={27.668} height={31} viewBox='0 0 27.668 31' {...props}>
    <path
      d='M15.504 27.267V4.387h5.862l-4.674 9.754 1.467-.006 4.497-9.427 3.459 9.449 1.424.007-3.787-10.022V2.75H15.8c.105-.236.164-.496.164-.77 0-1.093-.932-1.98-2.08-1.98-1.148 0-2.079.887-2.079 1.98 0 .274.059.533.163.77h-8.15v1.637h.007L.129 14.164l1.424-.007 3.458-9.45 4.498 9.428 1.466.006L6.3 4.386h5.93v22.882h-.001l-8.51 2.094V31h20.066v-1.638l-8.25-2.094h-.031z'
      fill='#333'
    />
    <path
      d='M10.976 15.274L0 15.25c0 3.472 1.874 6.263 5.346 6.263 3.47 0 5.695-2.488 5.63-6.24zM16.692 15.274c-.066 3.753 2.16 6.24 5.63 6.24 3.473 0 5.346-2.79 5.346-6.263l-10.976.023z'
      fill='#333'
    />
  </svg>
)

export default SvgChallenge4
