import React from 'react'

const SvgIcon4 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon4_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon4_svg__a)'>
      <path
        d='M30 54.49a1.1 1.1 0 01-.337-.061c-.214-.062-5.143-1.531-10.132-4.929-6.766-4.592-10.347-10.47-10.347-17.051V6.735c0-.49.275-.919.704-1.102a1.286 1.286 0 011.316.183c6.46 5.664 13.225 3.276 17.786.276.092-.061.183-.123.337-.184.244-.153.459-.214.673-.214.214 0 .46.03.673.214.123.061.245.153.337.184 4.53 3 11.327 5.388 17.786-.276.367-.306.888-.398 1.316-.183.429.183.704.643.704 1.102V32.48c0 6.612-3.581 12.55-10.347 17.112-4.99 3.367-9.949 4.806-10.132 4.867-.123 0-.215.03-.337.03zM11.633 9.214V32.48c0 12.857 15.826 18.673 18.367 19.53 2.54-.826 18.367-6.52 18.367-19.53V9.214C42.918 12.796 36.49 12.49 30 8.357c-6.49 4.133-12.918 4.408-18.367.857zm19.194-1.408c0 .03-.031.03 0 0-.031.03 0 .03 0 0zm-1.654 0c0 .03 0 .03 0 0 0 .03 0 .03 0 0z'
        fill='#2C405B'
      />
      <path
        d='M27.367 43.47a1.228 1.228 0 01-1.224-1.225V22.898c0-.674.55-1.225 1.224-1.225.674 0 1.225.551 1.225 1.225v19.347c0 .673-.551 1.224-1.225 1.224z'
        fill='#FD5C1F'
      />
      <path
        d='M20.54 30.98c-.305 0-.611-.123-.856-.368a1.22 1.22 0 010-1.745l6.826-6.826a1.22 1.22 0 011.745 0c.49.49.49 1.255 0 1.745l-6.857 6.826a1.209 1.209 0 01-.857.367zM33.857 38.326a1.228 1.228 0 01-1.224-1.224V17.755c0-.673.55-1.224 1.224-1.224.674 0 1.225.55 1.225 1.224v19.347c0 .673-.551 1.224-1.225 1.224z'
        fill='#FD5C1F'
      />
      <path
        d='M33.857 38.327c-.306 0-.612-.123-.857-.368a1.22 1.22 0 010-1.745l6.827-6.826a1.22 1.22 0 011.744 0c.49.49.49 1.255 0 1.745l-6.857 6.826c-.214.245-.55.368-.857.368z'
        fill='#FD5C1F'
      />
    </g>
  </svg>
)

export default SvgIcon4
