import React from 'react'

const SvgIcon10 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon10_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon10_svg__a)'>
      <path
        d='M27.747 50.83H15.25a2.088 2.088 0 01-2.086-2.086V42.5c0-5.742 4.674-10.416 10.416-10.416h12.502c2.187 0 4.287.681 6.072 1.968a2.084 2.084 0 002.441-3.377 14.444 14.444 0 00-8.513-2.757H23.58C15.541 27.912 9 34.457 9 42.5v6.25A6.256 6.256 0 0015.25 55h12.497a2.085 2.085 0 100-4.17zm2.085-24.998c5.752 0 10.416-4.664 10.416-10.416C40.248 9.664 35.584 5 29.832 5 24.08 5 19.416 9.664 19.416 15.416c0 5.752 4.664 10.416 10.416 10.416zm0-16.667a6.256 6.256 0 016.25 6.251 6.256 6.256 0 01-6.25 6.25 6.259 6.259 0 01-6.25-6.25 6.259 6.259 0 016.25-6.25z'
        fill='#2C405B'
      />
      <path
        d='M50.664 42.499c0-.016-.005-.026-.005-.036 0-.122-.015-.25-.036-.371-.01-.046-.025-.087-.04-.132-.026-.092-.046-.178-.082-.265a.554.554 0 00-.04-.076 2.008 2.008 0 00-.407-.59l-2.945-2.945a2.09 2.09 0 00-2.945 0 2.087 2.087 0 00-.417 2.335h-7.664a2.091 2.091 0 00-2.086 2.085c0 1.144.936 2.085 2.086 2.085h12.501c.137 0 .28-.015.412-.04a.094.094 0 01.036-.01 2.05 2.05 0 001.586-1.613c.03-.153.046-.29.046-.427zm-2.085 5.39H36.083c-.138 0-.28.016-.412.042a.094.094 0 01-.036.01 2.05 2.05 0 00-1.587 1.612c-.025.137-.04.275-.04.412 0 .015.005.025.005.036 0 .122.015.249.035.37.01.047.026.087.041.133.025.087.046.178.081.265.01.025.026.05.041.076.102.213.229.417.407.59l2.945 2.945a2.09 2.09 0 002.944 0c.631-.631.763-1.567.418-2.335h7.664a2.091 2.091 0 002.085-2.085 2.1 2.1 0 00-2.095-2.07z'
        fill='#2C405B'
      />
    </g>
  </svg>
)

export default SvgIcon10
