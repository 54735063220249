import React from 'react'

const SvgIcon1 = props => (
  <svg fill='none' width={60} height={60} viewBox='0 0 60 60' {...props}>
    <defs>
      <clipPath id='icon1_svg__a'>
        <rect width={60} height={60} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon1_svg__a)'>
      <path
        d='M40.834 25.43a15.3 15.3 0 00-6.943 1.658l-1.354-2.976a11.077 11.077 0 01-3.416 1.547l1.611 3.55a15.41 15.41 0 00-4.945 8.291H17.69l5.566-11.906a11.134 11.134 0 01-3.381-1.623l-6.322 13.53H3.75v18.75h35.86v-.047c.404.029.814.046 1.224.046 4.12 0 7.986-1.605 10.898-4.511A15.309 15.309 0 0056.25 40.84c0-8.496-6.914-15.41-15.416-15.41zM7.5 52.5V41.25h17.936a15.323 15.323 0 005.326 11.25H7.5zm33.334 0c-6.422 0-11.654-5.232-11.654-11.66 0-6.427 5.232-11.66 11.654-11.66 6.434 0 11.666 5.233 11.666 11.66 0 6.428-5.232 11.66-11.666 11.66z'
        fill='#2C405B'
      />
      <path
        d='M37.441 14.883c0 3.838-1.939 7.224-4.898 9.223a11.078 11.078 0 01-6.234 1.904c-1.06 0-2.086-.147-3.053-.422a11.135 11.135 0 01-3.381-1.623 11.107 11.107 0 01-4.7-9.082c0-6.153 4.981-11.133 11.134-11.133 6.152 0 11.132 4.98 11.132 11.133zM41.25 41.25v-7.5H37.5V45h11.25v-3.75h-7.5z'
        fill='#FD5C1F'
      />
    </g>
  </svg>
)

export default SvgIcon1
