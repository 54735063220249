import React from 'react'

const SvgChallenge3 = props => (
  <svg fill='none' width={29.862} height={30} viewBox='0 0 29.862 30' {...props}>
    <path
      d='M25.321 25.734l1.376 3.303-2.34.963-1.65-4.266H7.155L5.505 30l-2.34-.963 1.376-3.303H0v-2.477h29.862v2.477h-4.54zM26.01 0H3.716a2.467 2.467 0 00-2.477 2.477v16.79c0 1.375 1.1 2.476 2.477 2.476h22.293c1.376 0 2.477-1.1 2.477-2.477V2.477c0-1.376-1.1-2.477-2.477-2.477zM3.716 19.266V2.477h22.293v16.79H3.716z'
      fill='#333'
    />
    <path
      d='M13.624 17.202l-2.064-.275-1.79-3.578-2.614 3.302-1.927-1.513 3.854-4.817 2.064.275 1.789 3.578 8.67-9.495 2.064.688v6.055h-2.477V8.67l-7.57 8.532z'
      fill='#333'
    />
  </svg>
)

export default SvgChallenge3
